import {
  BridgeParams,
  calculateDebridgeCost,
  calculateEtaPF,
  calculateExecTime,
  calculateLiFiCost,
  calculateTotalEstimatedFeesInUSD,
  chainNameToChainId,
  createBridgeTxn,
  getBridgeQuote,
  getRoutes,
  getSkipRoute,
  rpcUrls,
} from "@tria-sdk/connect";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatUnits } from "viem";
import * as dn from "dnum";
import {
  AssetForTriaName,
  sendSwapLogsToSDK,
  ToastData,
  TriaSwapRoute,
  useToast,
} from "../../package/rx.core";
import { Opentria } from "../../package/ui.common";
import FromSelectDropDown2 from "../../package/ui.common/src/components/Swap/FromSelectDropDown2";
import ToSelectDropDown2 from "../../package/ui.common/src/components/Swap/ToSelectDropDown2";
import { SwapRouteSwitch } from "../../package/ui.common/src/components/SwapRouteSwitch";

import axios from "axios";

import { minBy } from "lodash";
import { useDebounce } from "react-use";
import { useDebouncedValue } from "../../package/rx.core/src/hooks/useDebounedValue";
import { isBestPathEnabled, ToToken } from "../../utils/best-path";
import { zeroAddress, ZeroAddressMap } from "../../utils/address-utils";
export const walletType = { embedded: true };
const AUTH_URL = process.env.REACT_APP_AUTH_URL;

interface ChainIdType {
  [key: string]: number;
}
interface BuyInjFee {
  gasFeesINUSD: string;
  finalTransferAmountInWei: string;
  finaltransferAmountWithFees: number;
}

interface Account {
  triaName: string;
  evm: {
    address: string;
  };
  nonEvm: {
    chainName: string;
    address: string;
  }[];
  aa: {
    address: string;
    accountType: string;
  };
}
const ChainId: ChainIdType = {
  MUMBAI: 80001,
  POLYGON: 137,
  AVALANCHE: 43114,
  ARBITRUM: 42161,
  OPTIMISM: 10,
  FANTOM: 250,
  GNOSIS: 100,
  FUSE: 122,
  ETH: 1,
  BINANCE: 56,
  SOLANA: 1151111081099710,
};

export const Swap2: React.FC = () => {
  const { openToastMessage, closeToastMessage, setToastData } = useToast();
  const [error, setError] = useState<string>();
  const [enteredAmountValueRaw, setEnteredAmountValueRaw] =
    useState<string>("");
  const enteredAmountValue = useDebouncedValue(enteredAmountValueRaw, 500);
  const [fromToken, setFromToken] = useState<AssetForTriaName>();
  const [toToken, setToToken] = useState<ToToken>();
  const [approveButtonDisable, setApproveButtonDisable] = useState(true);
  const [customToken, setCustomToken] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [fontSize, setFontSize] = useState(24);
  const [inputWidth, setInputWidth] = useState(30);
  const [routes, setRoutes] = useState<TriaSwapRoute[]>();
  const [selectedRoute, setSelectedRoute] = useState<TriaSwapRoute>();
  const location = useLocation();
  const useBestPathSDK = isBestPathEnabled({ fromToken, toToken });

  const account = useMemo(() => {
    const storageString = localStorage.getItem("tria.wallet.store.app");
    if (storageString) {
      return JSON.parse(storageString)?.addresses as Account;
    }
  }, []);

  const queryParams = new URLSearchParams(location.search);
  const isLite = queryParams.get("lite") === "true";

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!fromToken) {
      setError("Please Select Asset");
      return;
    }
    const inputValue = e.target.value;

    const validDecimalRegex = /^[0-9]*\.?[0-9]*$/;

    if (inputValue === "" || validDecimalRegex.test(inputValue)) {
      setEnteredAmountValueRaw(inputValue);
    } else {
    }

    // Skip font/width calculations if only a decimal point
    if (inputValue === ".") {
      setFontSize(24); // Default font size
      setInputWidth(30); // Default width
      return;
    }

    const calculateFontSize = () => {
      const baseFontSize = 24; // Start with a base font size in pixels
      const scalingFactor = 1.3; // Decrease factor per character, adjust as needed
      const minLength = 1; // Minimum length to start scaling
      const length = inputValue.length;
      let newFontSize = baseFontSize;

      if (length > minLength) {
        newFontSize = Math.max(
          baseFontSize - (length - minLength) * scalingFactor,
          12
        ); // Ensure a minimum font size
      }

      return newFontSize;
    };
    const calculateInputWidth = () => {
      const baseWidth = 30; // Base width in pixels
      const widthPerChar = inputValue?.length < 10 ? 14 : 8;
      return Math.max(baseWidth, inputValue.length * widthPerChar);
    };
    const fontSize = calculateFontSize();
    const width = calculateInputWidth();
    setFontSize(fontSize);
    setInputWidth(width);
  };

  const addData = useCallback(
    async (fee: string, type?: string) => {
      try {
        console.log(
          "account in addData",
          account?.evm?.address,
          account?.nonEvm[1]?.address,
          fee
        );
        const response = await fetch("https://chain-abs-poc.tria.so/add-data", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ethAddress:
              type === "EOA" ? account?.evm?.address : account?.aa?.address,
            injAddress: account?.nonEvm[1]?.address,
            fee,
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      } catch (e: any) {
        console.error(e.message);
        throw e;
      }
    },
    [account]
  );
  const createMintCalldata = async (
    recipientAddress: string,
    mintQuantity?: number | string
  ) => {
    try {
      const url = `https://dev.tria.so/api/v2/calldata/mint/create?recipientAddress=${recipientAddress}&mintQuantity=${mintQuantity}`;
      const res = await fetch(url);
      const data = await res.json();
      return data?.data?.calldata;
    } catch (err) {
      console.error(err);
    }
  };

  async function fetchTokenPrice(tokenId: string): Promise<number> {
    const url = `https://pro-api.coingecko.com/api/v3/coins/${tokenId}`;

    try {
      const { data } = await axios.get(url, {
        headers: {
          accept: "application/json",
          "x-cg-pro-api-key": "CG-XHndbvjcD5AriyHDPRBSxCwU",
        },
      });
      return data.market_data.current_price.usd;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("API Error:", error.response?.data);
      }
      throw error;
    }
  }

  const callBridge = useCallback(
    async (value: string): Promise<TriaSwapRoute | undefined> => {
      //  const authManager: AuthManager = await getAuthManager();
      const amount = Math.ceil(parseFloat(value));
      try {
        //  setMinting(true);

        console.log("call contract", account);
        if (!fromToken) {
          return;
        }
        /**
         * Input Params
         * 1. src chain, src token, recipient address on Solana
         * 2. default - amount - 0.005 SOL equivalent src token amount and platform fees
         * 3. Mint 1 Token, Get 0.002 SOL (rest goes for token account rent 0.002x SOL, and fees) - will add better expense calculation
         */
        const extCalldata = await createMintCalldata(
          account?.nonEvm[0]?.address || "",
          amount
        );
        console.log({ extCalldata });
        // const extCalldata = "0x"
        const solAmount = 0.01;

        const tokenAddress =
          ZeroAddressMap[
            `${fromToken?.chainName?.toUpperCase()}_${fromToken?.symbol?.toUpperCase()}` ||
              "ETH"
          ];
        console.log(
          "tokenadd",
          tokenAddress,
          `${fromToken?.symbol?.toUpperCase()}_${fromToken?.chainName?.toUpperCase()}`
        );
        const params =
          customToken?.index === 0
            ? {
                src: {
                  chain: {
                    // chainName: srcToken.chainName,
                    customChain: {
                      type: "EVM",
                      chainName: fromToken.chainName,
                      chainLogo: fromToken.chainLogo,
                      currencyName: fromToken.name,
                      currencySymbol: fromToken.symbol,
                      nativeTokenLogo: fromToken.logoUrl,
                      // chainId: 43114, rpcUrl: "https://api.avax.network/ext/bc/C/rpc"
                      // chainId: 137, rpcUrl: "https://polygon-mainnet.g.alchemy.com/v2/kcuWVV9ss_iLWJ2Lw6xdHbmtZYixfY7Z"
                      // chainId: 56,
                      // rpcUrl: "https://binance.llamarpc.com"
                      chainId:
                        chainNameToChainId[fromToken?.chainName?.toUpperCase()],
                      rpcUrl: rpcUrls[fromToken.chainName?.toUpperCase()],
                    },
                  },
                  token: {
                    address: tokenAddress,
                    amountInUnits: "auto",
                  },
                  senderAddress: account?.evm.address!,
                },
                dest: {
                  chainId: 7565164,
                  token: {
                    address: "11111111111111111111111111111111",
                    amountInUnits: (solAmount * 10 ** 9).toString(),
                  },
                  recipientAddress: extCalldata
                    ? "exe59FS5cojZkPJVDFDV8RnXCC7wd6yoBjsUtqH7Zai"
                    : account?.nonEvm[0]?.address,
                  authorityAddress: account?.nonEvm[0]?.address,
                  externalCall: {
                    version: "solana_1",
                    fields: {
                      calldata: extCalldata,
                    },
                  },
                },
              }
            : {
                src: {
                  chain: {
                    customChain: {
                      type: "EVM",
                      chainName: fromToken.chainName,
                      chainLogo: fromToken.chainLogo,
                      currencyName: fromToken.name,
                      currencySymbol: fromToken.symbol,
                      nativeTokenLogo: fromToken.logoUrl,
                      // chainId: 43114, rpcUrl: "https://api.avax.network/ext/bc/C/rpc"
                      // chainId: 137, rpcUrl: "https://polygon-mainnet.g.alchemy.com/v2/kcuWVV9ss_iLWJ2Lw6xdHbmtZYixfY7Z"
                      // chainId: 56,
                      // rpcUrl: "https://binance.llamarpc.com"
                      chainId: chainNameToChainId[fromToken.chainName],
                      rpcUrl: rpcUrls[fromToken.chainName],
                    },
                  },
                  token: {
                    address: tokenAddress,
                    amountInUnits: "auto",
                  },
                  senderAddress: account?.evm.address!,
                },
                dest: {
                  chainId: 7565164,
                  token: {
                    address: "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263",
                    amountInUnits: (1000 * amount * 10 ** 5).toString(),
                  },
                  recipientAddress: account?.nonEvm[0]?.address,
                  authorityAddress: account?.nonEvm[0]?.address,
                },
              };

        const src = params.src;
        const dest = params.dest;
        if (tokenAddress && tokenAddress !== zeroAddress) {
          const bridgeQuote = await getBridgeQuote({
            src,
            dest,
          } as BridgeParams);

          const amount = Number(
            formatUnits(
              BigInt(bridgeQuote.data?.tx.allowanceValue ?? "0"),
              bridgeQuote.data?.estimation.srcChainTokenIn.decimals ?? 0
            )
          );

          console.log("chainName", { chainName: fromToken?.chainName });
        }
        sendSwapLogsToSDK(["[BP] No BasePath", "[BP] Pathfinders subscribed"]);

        console.log("authManager.createBridgeTxn demoapp");
        const createTxnRes = await createBridgeTxn({
          callData: { src, dest } as BridgeParams,
          baseUrl: "https://api.development.tria.so",
        });

        const time = Math.floor(Math.random() * 3) + 3;
        const cost = await calculateDebridgeCost(createTxnRes);
        // Debridge
        sendSwapLogsToSDK([
          "[BP] PF1: SL01",
          `[BP] -> Cost: ${cost} USD`,
          `[BP] -> TTS: ${time * 1000} ms`,
        ]);
        console.log("createTxnRes", createTxnRes);
        if (!createTxnRes?.data) return;
        const debridgeRoute: TriaSwapRoute = {
          toAmountInUsd: String(amount),
          id: createTxnRes?.data?.orderId,
          fromChainData: {
            logo: fromToken?.chainLogo,
            name: fromToken?.chainName,
          },
          fromTokenData: {
            tokenAddress: (fromToken?.tokenAddress as string) || zeroAddress,
            name: fromToken?.name,
            logoUrl: fromToken?.logoUrl,
            symbol: fromToken?.symbol,
          },
          toChainData: {
            logo: customToken?.chainLogo || "",
            name: customToken?.chainName || "",
          },
          toTokenData: {
            tokenAddress: customToken?.tokenAddress || zeroAddress,
            name: customToken?.name || "",
            logoUrl: customToken?.logoUrl || "",
            symbol: customToken?.symbol || "",
          },
          fromChainId: ChainId[fromToken?.chainName?.toUpperCase()],
          amount: parseFloat(value),
          toChainId: customToken?.chainId,
          default: true,
          toAmountInUnits: parseFloat(
            createTxnRes?.data?.estimation?.srcChainTokenOut?.amount
          ),
          toTokenDecimals:
            createTxnRes?.data?.estimation?.srcChainTokenOut?.decimals,
          toolLogoUri: "/icons/memeCoinLogo.png",
          toolName: createTxnRes?.data?.estimation?.srcChainTokenOut?.name,
          order: createTxnRes?.data?.orderId,
          gasFeeInUsd: 6,
          txnObject: createTxnRes?.data?.tx,
          provider: "debridge",
          providerName: "SL01",
          fromAmountInUsd: cost.toString(),
          eta: {
            cost,
            time,
          },
          hideGasFee: true,
          executionDuration: time,
        };
        return debridgeRoute;
      } catch (err) {
        console.log("in catch block");
        if (
          err
            ?.toString()
            .includes(
              "cannot estimate gas; transaction may fail or may require manual gas limit "
            ) ||
          err
            ?.toString()
            .includes("It appears that senderAddress does not have enough")
        ) {
          console.error("Insufficient Funds for Transaction!");
        } else if (err?.toString().includes("user rejected transaction")) {
          console.error("Transaction Cancelled!");
        } else {
          console.error(`${err}`);
        }
        //  setMinting(false);
      }
    },
    [
      account,
      fromToken,
      customToken?.index,
      customToken?.chainLogo,
      customToken?.chainName,
      customToken?.tokenAddress,
      customToken?.name,
      customToken?.logoUrl,
      customToken?.symbol,
      customToken?.chainId,
    ]
  );

  const handleBuyInjRoutes = useCallback(
    async (value: string) => {
      if (account?.evm.address) {
        try {
          const response = await fetch(
            "https://chain-abs-poc.tria.so/get-fee",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                stableCoinContractAddress:
                  "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
                transferAmount: Math.ceil(parseFloat(value)),
              }),
            }
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const buyInjFee = (await response.json()) as BuyInjFee;
          console.log("buy inj fee response", buyInjFee);

          const basePathTime = Math.floor(Math.random() * 3) + 9;
          const buyInjBasePathRoute: TriaSwapRoute = {
            id: Date.now()?.toString(),
            fromChainData: {
              logo: fromToken?.chainLogo || "",
              name: fromToken?.chainName || "",
            },
            toChainData: {
              logo: toToken?.chainLogo || "",
              name: toToken?.chainName || "",
            },
            fromTokenData: {
              tokenAddress: (fromToken?.tokenAddress as string) || zeroAddress,
              name: fromToken?.name || "",
              symbol: fromToken?.symbol || "",
              logoUrl: fromToken?.logoUrl || "",
              type: fromToken?.wallet?.type,
            },
            toTokenData: {
              tokenAddress: customToken?.tokenAddress || zeroAddress,
              name: customToken?.name || "",
              symbol: customToken?.symbol || "",
              logoUrl: customToken?.logoUrl,
            },
            fromChainId: ChainId[fromToken?.chainName?.toUpperCase() || ""],
            amount: buyInjFee?.finaltransferAmountWithFees,
            toChainId: customToken?.chainId,
            default: true,
            executionDuration: basePathTime,
            toAmountInUnits: parseFloat(
              buyInjFee?.finalTransferAmountInWei || "0"
            ),
            gasFeeInUsd: parseFloat(buyInjFee?.gasFeesINUSD || "0"),
            toTokenDecimals: customToken?.decimals,
            order: Date.now()?.toString(),
            provider: "base-path",
            fromAmountInUsd:
              buyInjFee?.finaltransferAmountWithFees?.toString() ?? "0",
            hideGasFee: true,
            tags: [],
            providerName: "Base Path",
          };
          let basePathCost = buyInjFee.gasFeesINUSD;

          sendSwapLogsToSDK([
            "[BP] Pathfinders subscribed",
            "[BP] BasePath:",
            `[BP] -> Cost: ${basePathCost} USD`,
            `[BP] -> TTS: ${basePathTime * 1000} ms`,
            "[BP] PF1: SL03",
          ]);

          const { success, data } = await getSkipRoute({
            dest_asset_chain_id: "injective-1",
            dest_asset_denom: "inj",
            source_asset_chain_id: "42161",
            source_asset_denom: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
            amount_in: buyInjFee.finalTransferAmountInWei.toString(),
            allow_multi_tx: true,
            cumulative_affiliate_fee_bps: "0",
          });
          console.log("buy inj skip response", success, data);
          let buyInjSkipRoute: TriaSwapRoute | undefined = undefined;

          let winnerRoute: TriaSwapRoute | undefined = undefined;
          let allRoutes: TriaSwapRoute[] = [];

          if (success) {
            let skipCost = 10;
            if (data) {
              skipCost = await calculateTotalEstimatedFeesInUSD(data);
            }
            const skipTime = data?.estimated_route_duration_seconds || 300;
            buyInjSkipRoute = {
              id: Date.now()?.toString(),
              fromChainData: {
                logo: fromToken?.chainLogo || "",
                name: fromToken?.chainName || "",
              },
              toChainData: {
                logo: toToken?.chainLogo || "",
                name: toToken?.chainName || "",
              },
              fromTokenData: {
                tokenAddress:
                  (fromToken?.tokenAddress as string) || zeroAddress,
                name: fromToken?.name || "",
                symbol: fromToken?.symbol || "",
                logoUrl: fromToken?.logoUrl || "",
                type: fromToken?.wallet?.type,
              },
              toTokenData: {
                tokenAddress: customToken?.tokenAddress || zeroAddress,
                name: customToken?.name || "",
                symbol: customToken?.symbol || "",
                logoUrl: customToken?.logoUrl,
              },
              fromChainId: ChainId[fromToken?.chainName?.toUpperCase() || ""],
              amount: parseFloat(data?.usd_amount_in || "0"),
              toChainId: customToken?.chainId,
              default: false,
              executionDuration: skipTime,
              toAmountInUnits: parseFloat(data?.amount_out || "0"),
              gasFeeInUsd: parseFloat(
                data?.estimated_fees?.[0]?.usd_amount || "0"
              ),
              toTokenDecimals: customToken?.decimals,
              order: Date.now()?.toString(),
              provider: "skip",
              fromAmountInUsd: data?.usd_amount_in || "0",
              hideGasFee: true,
              tags: [],
              providerName: "SL03",
            };
            sendSwapLogsToSDK([
              `[BP] -> Cost: ${skipCost || 10} USD`,
              `[BP] -> TTS: ${
                data?.estimated_route_duration_seconds || 4 * 10 ** 3
              } ms`,
              "[BP] Calculating relative efficiencies",
            ]);

            const etaPF = calculateEtaPF(
              basePathCost,
              skipCost.toString(),
              basePathTime.toString(),
              skipTime?.toString()
            );
            sendSwapLogsToSDK([`[BP] \u03B7(PF/PF1): ${etaPF?.toFixed(4)}`]);

            allRoutes = [buyInjBasePathRoute];
            if (buyInjSkipRoute) {
              allRoutes.push(buyInjSkipRoute);
            }
            if (etaPF < 0) {
              winnerRoute = buyInjBasePathRoute;
              buyInjBasePathRoute.tags = ["Best Path"];
              sendSwapLogsToSDK(["[BP] Winning PF: BasePath"]);
            } else {
              winnerRoute = buyInjSkipRoute;
              buyInjSkipRoute.tags = ["Best Path"];
              sendSwapLogsToSDK(["[BP] Winning PF: SL01"]);
            }
            const lowestCost = minBy(allRoutes, (el) => el.fromAmountInUsd);
            lowestCost?.tags?.push("Low Cost");

            console.log(
              "setting buy inj routes",
              buyInjBasePathRoute,
              buyInjSkipRoute
            );
          } else {
            console.error("No Route Found");
          }
          if (!buyInjBasePathRoute && !buyInjSkipRoute) {
            console.error("No routes found!!");
            return;
          }
          setRoutes(allRoutes);
          setSelectedRoute(winnerRoute);
          setApproveButtonDisable(false);
        } catch (err) {
          if (
            err
              ?.toString()
              .includes(
                "cannot estimate gas; transaction may fail or may require manual gas limit "
              )
          ) {
            console.error("error in sending", err);
            console.error("Insufficient Funds for Transaction!");
          } else if (err?.toString().includes("user rejected transaction")) {
            console.error("Transaction Cancelled!");
          } else {
            console.error(`${err}`);
          }
        }
      } else {
        console.error("Error calculating fee!!");
      }
    },
    [
      account?.evm.address,
      customToken?.chainId,
      customToken?.decimals,
      customToken?.logoUrl,
      customToken?.name,
      customToken?.symbol,
      customToken?.tokenAddress,
      fromToken?.chainLogo,
      fromToken?.chainName,
      fromToken?.logoUrl,
      fromToken?.name,
      fromToken?.symbol,
      fromToken?.tokenAddress,
      toToken?.chainLogo,
      toToken?.chainName,
    ]
  );

  const handleGetRoutes = useCallback(
    async (value: string) => {
      try {
        if (!fromToken || !value || !(toToken || customToken)) {
          console.error("Please select assets");
          return;
        }
        if (useBestPathSDK) {
          return;
        }
        if (customToken && customToken?.index === 0) return;
        else if (customToken && customToken?.index === 2) {
          setLoading(true);
          const data: ToastData = {
            title: "Finding Best Path…",
            status: "success",
          };
          setToastData(data);
          await handleBuyInjRoutes(value);
          return;
        }
        setLoading(true);
        const data: ToastData = {
          title: "Finding Best Path…",
          status: "success",
        };
        setToastData(data);
        let debridgeRoute: TriaSwapRoute | undefined;
        if (customToken) {
          debridgeRoute = await callBridge(value);
          console.log("debrigeRoute", debridgeRoute);
        }
        let amountWithDecimal: string | undefined = undefined;
        console.log("from token in routes", fromToken, customToken, toToken);
        if (customToken) {
          const priceOfBonk = await fetchTokenPrice("bonk");
          const priceOfFromToken = await fetchTokenPrice(
            fromToken?.symbol === "USDC" ? "usd-coin" : "ethereum"
          );
          const amountToCalculate =
            (1000 * parseFloat(value) * priceOfBonk) / priceOfFromToken;
          amountWithDecimal = BigInt(
            Math.floor(
              parseFloat(amountToCalculate?.toString()) *
                10 ** fromToken?.decimals
            )
          ).toString();
          console.log("from token in routes calling this block");
        } else {
          amountWithDecimal = BigInt(
            Math.floor(parseFloat(value) * 10 ** fromToken?.decimals)
          ).toString();
          console.log("from token in routes calling this block 2");
        }
        console.log(
          "from token in routes calling this block 3",
          amountWithDecimal,
          typeof amountWithDecimal
        );
        console.log("to token in swap", toToken);
        const routesRequest = {
          fromChainId: ChainId[fromToken.chainName],
          fromAmount: amountWithDecimal,
          fromTokenAddress: (fromToken?.tokenAddress as string) || zeroAddress,
          toChainId: toToken ? toToken.chainId : ChainId["SOLANA"],
          toTokenAddress: toToken
            ? (toToken?.tokenAddress as string) || zeroAddress
            : "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263",
        };
        console.log("routesRequest", routesRequest);
        let lifiRoute: TriaSwapRoute | undefined;
        let etaPF: any = 0;
        try {
          const routes = await getRoutes(routesRequest);
          console.log("routes in wallet", routes);
          const lifiCost = calculateLiFiCost(routes);
          const lifiExecTime = calculateExecTime(routes);
          // LiFi
          sendSwapLogsToSDK([
            "[BP] PF2: SL02",
            `[BP] -> Cost: ${lifiCost} USD`,
            `[BP] -> TTS: ${lifiExecTime * 10 ** 3} ms`,
          ]);

          etaPF = calculateEtaPF(
            debridgeRoute?.eta?.cost.toString() || "2.7",
            lifiCost.toString(),
            debridgeRoute?.eta?.time.toString() || "3",
            lifiExecTime?.toString()
          );
          sendSwapLogsToSDK([`[BP] \u03B7(PF1/PF2): ${etaPF?.toFixed(4)}`]);
          if (etaPF < 0) {
            sendSwapLogsToSDK(["[BP] Winning PF: SL01"]);
          } else {
            sendSwapLogsToSDK(["[BP] Winning PF: SL02"]);
          }
          lifiRoute = {
            id: routes[0]?.id,
            fromChainData: {
              logo: fromToken?.chainLogo,
              name: fromToken?.chainName,
            },
            fromTokenData: {
              tokenAddress: (fromToken?.tokenAddress as string) || zeroAddress,
              name: fromToken?.name,
              logoUrl: fromToken?.logoUrl,
              symbol: fromToken?.symbol,
            },
            toChainData: {
              logo: toToken?.chainLogo || "",
              name: toToken?.chainName || "",
            },
            toTokenData: {
              tokenAddress: toToken?.tokenAddress || zeroAddress,
              name: toToken?.name || "",
              logoUrl: toToken?.logoUrl || "",
              symbol: toToken?.symbol || "",
            },
            fromChainId: ChainId[fromToken?.chainName?.toUpperCase()],
            amount: parseFloat(value),
            toChainId: toToken?.chainId,
            default: true,
            toAmountInUnits: parseFloat(routes[0]?.toAmount),
            toTokenDecimals: routes[0]?.toToken?.decimals,
            executionDuration: routes[0]?.steps[0]?.estimate?.executionDuration,
            toolName: routes[0]?.steps[0]?.estimate?.tool,
            order: routes[0]?.id,
            gasFeeInUsd: parseFloat(routes[0]?.gasCostUSD || "0"),
            provider: "lifi",
            toAmountInUsd: routes[0].toAmountUSD,
            fromAmountInUsd: routes[0].fromAmountUSD,
            hideGasFee: true,
            tags: [],
            providerName: "SL02",
          };
        } catch (err) {
          console.log("routes in wallet");
        }
        console.log("setting routes for txn", debridgeRoute, lifiRoute);
        if (!lifiRoute && !debridgeRoute) return;
        console.log("stuck here");
        if (debridgeRoute && lifiRoute) {
          let allRoutes: TriaSwapRoute[] = [];
          if (etaPF < 0) {
            debridgeRoute.tags = ["Best-path"];
            setSelectedRoute(debridgeRoute);
            allRoutes = [debridgeRoute, lifiRoute];
            setRoutes(allRoutes);
          } else {
            lifiRoute.tags = ["Best-path"];
            debridgeRoute.tags = ["Faster"];
            setSelectedRoute(lifiRoute);
            allRoutes = [lifiRoute, debridgeRoute];
            setRoutes(allRoutes);
          }

          const lowestCost = minBy(allRoutes, (el) => el.fromAmountInUsd);
          lowestCost?.tags?.push("Low Cost");
        } else if (lifiRoute) {
          lifiRoute.tags = ["Best-path"];
          setRoutes([lifiRoute]);
          setSelectedRoute(lifiRoute);
        } else if (debridgeRoute) {
          debridgeRoute.tags = ["Best-path"];
          setRoutes([debridgeRoute]);
          setSelectedRoute(debridgeRoute);
        }
        console.log("setting approve button disable");
        setApproveButtonDisable(false);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
        closeToastMessage();
      }
    },
    [
      callBridge,
      closeToastMessage,
      customToken,
      fromToken,
      handleBuyInjRoutes,
      setToastData,
      toToken,
      useBestPathSDK,
    ]
  );

  const [, cancel] = useDebounce(
    () => {
      console.log("here called");
      setSelectedRoute(undefined);
      setRoutes(undefined);
      handleGetRoutes(enteredAmountValue);
    },
    500,
    [enteredAmountValue, handleGetRoutes]
  );

  useEffect(() => {
    return () => {
      cancel();
    };
  }, [cancel]);

  useEffect(() => {
    setError("");
  }, [customToken, enteredAmountValue, fromToken, fromToken?.balanceInTokens]);

  useEffect(() => {
    if (error) {
      console.log("first", error);
      // setToastData({ status: "error", title: error });
      // openToastMessage();
      // setTimeout(closeToastMessage, 4000);
    }
  }, [closeToastMessage, error, openToastMessage, setToastData]);

  useEffect(() => {
    console.log("routes for txn", routes);
  }, [routes]);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      parseFloat(enteredAmountValue) > 0 &&
      fromToken != null &&
      toToken != null &&
      !error
    ) {
      setApproveButtonDisable(false);
    }
    if (
      useBestPathSDK &&
      parseFloat(enteredAmountValue) > 0 &&
      fromToken != null &&
      toToken != null
    ) {
      setApproveButtonDisable(false);
    }
  }, [enteredAmountValue, fromToken, toToken, error, useBestPathSDK]);

  const setEnteredAmountInUsd = async (percentChange: number) => {
    if (!fromToken) {
      return setError("please select assest");
    }
    setEnteredAmountValueRaw(
      (fromToken?.balanceInTokens * percentChange).toFixed(6)
    );
  };

  console.log("fromToken", fromToken, toToken);
  const handleSwap = useCallback(async () => {
    console.log("calling handleSwap", selectedRoute);
    if (useBestPathSDK && fromToken && toToken) {
      let fromTokenAddress = fromToken.tokenAddress;
      if (fromToken.isNativeToken) {
        fromTokenAddress =
          ZeroAddressMap[
            `${fromToken?.chainName?.toUpperCase()}_${fromToken?.symbol?.toUpperCase()}` ||
              "ETH"
          ];
      }
      const fetchParams = {
        senderAddress: account?.evm?.address,
        sourceChainId: String(fromToken.customChain?.chainId),
        sourceChainTokenDetails: {
          tokenAddress: fromTokenAddress,
          tokenDecimals: fromToken.decimals ?? 18,
          isNative: fromToken.isNativeToken,
          name: fromToken.name,
          symbol: fromToken.symbol,
          logoUrl: fromToken.logoUrl,
          chainName: fromToken.chainName,
          chainLogoUrl: fromToken.chainLogo,
        },
        sourceChainTokenAmount: dn.toString(
          dn.multiply(enteredAmountValue, Math.pow(10, fromToken.decimals), {
            decimals: fromToken.decimals,
          })
        ),
        targetChainId: String(toToken.chainId),
        targetChainTokenDetails: {
          tokenAddress: toToken?.tokenAddress,
          tokenDecimals: toToken?.decimal,
          isNative: toToken?.isNative,
          name: toToken.name,
          symbol: toToken.symbol,
          logoUrl: toToken.logoUrl,
          chainName: toToken.chainName,
          chainLogoUrl: toToken.chainLogo,
        },
        receiverAddress: account?.evm?.address,
        bestPathProtocol: "SKYNET",
      };

      const encodedParams = window.encodeURI(
        btoa(JSON.stringify({ queryParams: fetchParams }))
      );
      console.log("encodedParams", fetchParams, encodedParams);
      window.open(`${AUTH_URL}/best-path/${encodedParams}`, "_self");
      return;
    }
    if (selectedRoute?.provider === "debridge") {
      const encodedParams = btoa(
        JSON.stringify({
          chain: { chainName: fromToken?.chainName },
          txnObject: {
            ...selectedRoute?.txnObject,
            from: account?.evm?.address,
          },
          addressType: "EOA",
          enableMaxFeeEstimation: true,
          fromWallet: true,
        })
      );
      window.open(`${AUTH_URL}/broadcasttxn/${encodedParams}`, "_self");
    } else if (selectedRoute?.provider === "skip") {
      const message: ToastData = { title: "Coming Soon", status: "success" };
      setToastData(message);
      openToastMessage();
      setTimeout(() => {
        closeToastMessage();
      }, 2000);
    } else if (selectedRoute?.provider === "base-path") {
      await addData(
        selectedRoute.gasFeeInUsd?.toString(),
        selectedRoute.fromTokenData.type
      );
      const encodedParams = btoa(
        JSON.stringify({
          amount: selectedRoute.amount,
          senderName: account?.triaName,
          senderAddress:
            selectedRoute.fromTokenData.type === "EOA"
              ? account?.evm?.address
              : account?.aa?.address,
          recepientAddress: "0xb41E8a43AA70e94517a4085715E8dD17AC29F539",
          darkMode: true,
          tokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
          fromWallet: true,
          addressType: selectedRoute.fromTokenData.type,
          chain: { chainName: "ARBITRUM" },
          isMobile: false,
          liteMode: isLite,
          fromSwap: true,
        })
      );
      window.open(`${AUTH_URL}/send/${encodedParams}`, "_self");
    } else {
      if (!fromToken || !(toToken || customToken)) {
        console.error("Tokens to swap not found! ");
        return;
      }
      const payload = {
        fromChainData: {
          logo: fromToken?.chainLogo,
          name: fromToken?.chainName,
        },
        fromTokenData: fromToken,
        toChainData: {
          id: customToken?.chainId || toToken?.chainId,
          logoURI: customToken?.chainLogo || toToken?.chainLogo,
          name: customToken?.chainName || toToken?.chainName,
        },
        toTokenData: {
          address: customToken?.tokenAddress || toToken?.tokenAddress,
          logoURI: customToken?.logoUrl || toToken?.logoUrl,
          symbol: customToken?.symbol || toToken?.symbol,
        },
        fromChainId: ChainId[fromToken?.chainName?.toUpperCase()],
        amount: enteredAmountValue,
        addressType: fromToken.wallet.type,
        toChainId: customToken?.chainId || toToken?.chainId,
        fromWallet: true,
        liteMode: isLite,
        bonkMultiplicationFactor:
          selectedRoute?.fromAmountInUsd &&
          selectedRoute?.toAmountInUsd &&
          customToken &&
          customToken?.index === 1
            ? parseFloat(selectedRoute?.fromAmountInUsd) /
              (parseFloat(selectedRoute?.toAmountInUsd) +
                (parseFloat(process.env.REACT_APP_MARGIN_PERCENT!) / 100) *
                  parseFloat(selectedRoute?.toAmountInUsd))
            : 1,
      };
      console.log("payLoad", payload);
      console.log(
        "multiplication factor in wallet",
        parseFloat(selectedRoute?.fromAmountInUsd || "0") /
          (parseFloat(selectedRoute?.toAmountInUsd || "0") +
            0.2 * parseFloat(selectedRoute?.toAmountInUsd || "0")),
        "margin percent",
        parseFloat(process.env.REACT_APP_MARGIN_PERCENT!) / 100
      );
      const encodedParams = btoa(JSON.stringify(payload));

      window.open(`${AUTH_URL}/swap/${encodedParams}`, "_self");
    }
  }, [
    account?.evm?.address,
    account?.triaName,
    addData,
    closeToastMessage,
    customToken,
    enteredAmountValue,
    fromToken,
    isLite,
    openToastMessage,
    selectedRoute,
    setToastData,
    toToken,
    useBestPathSDK,
  ]);

  return (
    <div>
      <div
        className={`${isLite ? "w-[100vw] " : "w-[53vh]"}  h-[100vh] relative`}
      >
        <div
          className={`${
            isLite ? "" : "p-[1.92vh]"
          } flex-col justify-between items-center flex w-full h-full`}
        >
          <div
            className={`${
              isLite ? "p-3" : ""
            } w-full flex-col justify-start items-center flex self-stretch flex-[1_1_auto]`}
          >
            <div className="self-stretch rounded-2xl flex-col justify-center items-center gap-[0.96vh] flex">
              <div className="self-stretch flex-col justify-center items-center gap-[0.96vh] flex">
                <div
                  className={`${
                    isLite ? "" : "px-[1.92vh]"
                  } self-stretch h-[8.6vh]  py-[0.96vh] rounded-[2.5vh] flex-col justify-center items-center gap-[0.96vh] flex`}
                >
                  <div className="self-stretch justify-start items-center gap-[0.96vh] inline-flex">
                    <div className="w-[2.2vh] h-[2.2vh] relative">
                      <div
                        className="w-[2.2vh] h-[2.2vh] left-0 top-0 cursor-pointer relative"
                        onClick={() => {
                          if (isLite) {
                            navigate("/lite");
                          } else {
                            navigate("/home");
                          }
                        }}
                      >
                        <img
                          alt="arrow"
                          src="/icons/arrow-right2.svg"
                          className="dark:visible invisible w-0 dark:w-[2.6vh] rotate-180"
                        />
                        <img
                          alt="arrow"
                          src="/icons/arrow-left.svg"
                          className="dark:invisible visible dark:w-0 "
                        />
                      </div>
                    </div>
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-[0.48vh] inline-flex">
                      <div className="self-stretch justify-start items-center gap-[0.96vh] inline-flex">
                        <div
                          className={`${
                            isLite ? "text-sm" : "text-lg"
                          } text-center font-montserrat dark:text-neutral-50 font-semibold  leading-normal`}
                        >
                          Convert
                        </div>
                        <div
                          className={`${
                            isLite ? "text-xs" : "text-sm"
                          } text-center font-montserrat dark:text-zinc-500 text-fontPrimaryColor text-opacity-30 font-semibold  leading-tight`}
                        >
                          (Swap)
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch py-[1.92vh] flex-col justify-start items-center gap-[0.96vh] flex">
              <div className="self-stretch pb-[3.84vh] rounded-2xl -2 flex-col justify-start items-center gap-[0.96vh] flex">
                <div className="h-[20vh] pb-[0.96vh] pb-[3.48vh flex-col justify-end items-center gap-[0.96vh] inline-flex">
                  <div className="self-stretch justify-center items-center gap-[0.96vh] inline-flex">
                    {(customToken?.logoUrl || fromToken?.logoUrl) && (
                      <div className="w-[4.8vh] h-[4.8vh] relative">
                        <div className="w-[4.8vh] h-[4.8vh] left-0 top-[.36vh] absolute dark:bg-zinc-800 bg-bgOfCircle rounded-[4.8vh] overflow-hidden">
                          <img
                            alt="logo"
                            src={
                              customToken?.logoUrl || fromToken?.logoUrl || ""
                            }
                            className={`${
                              fromToken?.name?.toUpperCase() === "FUSE"
                                ? "scale-[124%]"
                                : ""
                            } `}
                          />
                        </div>
                      </div>
                    )}
                    <div className="justify-center items-baseline flex">
                      <input
                        type="text"
                        className={`text-start font-['e-Ukraine' font-bold bg-primaryColor dark:bg-primaryDarkColor dark:text-white  focus:outline-none focus:border-none focus:ring-0}`}
                        placeholder="0"
                        value={enteredAmountValueRaw}
                        onChange={handleAmountChange}
                        style={{
                          fontSize: `${fontSize}px`,
                          width: `${inputWidth}px`,
                        }}
                      />
                    </div>
                  </div>{" "}
                  {!customToken ? (
                    <div className="self-stretch justify-center items-start gap-[.48vh] inline-flex">
                      <div className="text-center text-zinc-500  md:text-xl text-lg placeholder:0 font-semibold  leading-normal">
                        $
                        {enteredAmountValue !== "" &&
                        enteredAmountValue !== "." &&
                        fromToken &&
                        !isNaN(parseFloat(enteredAmountValue))
                          ? (
                              (fromToken.balanceInUSD /
                                fromToken.balanceInTokens) *
                              parseFloat(enteredAmountValue)
                            ).toFixed(4)
                          : "0"}
                      </div>
                    </div>
                  ) : null}
                  <div className="self-stretch h-[8.64vh] py-[1.92vh] flex-col justify-center items-center gap-[0.96vh] flex">
                    <div className="justify-start items-center inline-flex">
                      <div
                        className="h-[4.8vh] px-[2.4vh] py-[1.44vh] dark:bg-bgOfmax  bg-fontColorNftCardDark rounded-tl-2xl rounded-bl-2xl shadow border dark:border-navigatorBackgroundActiveDark justify-center items-center gap-[.48vh] flex  cursor-pointer"
                        onClick={() => {
                          setEnteredAmountInUsd(0.25);
                        }}
                      >
                        <div className="text-center dark:text-white text-fontLightColor dark:text-opacity-75 md:text-base text-sm font-medium  leading-none">
                          25%
                        </div>
                      </div>
                      <div
                        className="h-[4.8vh] px-[2.4vh] py-[1.44vh] dark:bg-bgOfmax bg-fontColorNftCardDark shadow border dark:border-navigatorBackgroundActiveDark justify-center items-center gap-[.48vh] flex cursor-pointer"
                        onClick={() => {
                          setEnteredAmountInUsd(0.5);
                        }}
                      >
                        <div className="text-center dark:text-white text-fontLightColor dark:text-opacity-75 md:text-base text-sm font-medium  leading-none ">
                          50%
                        </div>
                      </div>
                      <div
                        className="h-[4.8vh] px-[2.4vh] py-[1.44vh]  dark:bg-bgOfmax bg-fontColorNftCardDark rounded-tr-2xl rounded-br-2xl shadow border dark:border-navigatorBackgroundActiveDark items-center justify-center  gap-[.48vh] flex cursor-pointer"
                        onClick={() => {
                          setEnteredAmountInUsd(1);
                        }}
                      >
                        <div className="text-center font-montserrat dark:text-white text-fontDarkColor text-opacity-90 md:text-base text-sm font-medium  leading-none ">
                          MAX
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div className="self-stretch flex-col justify-start items-center gap-[1.44vh] flex relative">
                  <FromSelectDropDown2
                    fromToken={fromToken}
                    setFromToken={setFromToken}
                    liteMode={isLite}
                  />
                  <ToSelectDropDown2
                    toToken={toToken}
                    setToToken={setToToken}
                    setCustomToken={setCustomToken}
                    customToken={customToken}
                    fromToken={fromToken}
                    liteMode={isLite}
                  />
                  <div className="px-[1.26vh] py-[1.44vh] left-[calc(50%-1vh)] top-[calc(50%-2.7vh)] absolute dark:bg-swapIcon bg-swapIconLight rounded-[1.6vh] shadow justify-center items-center gap-[1vh] inline-flex">
                    <div className="w-[3.24vh] h-[3.24vh] relative">
                      <img
                        alt="swap"
                        src="/icons/swap.svg"
                        className="dark:visible invisible w-0 dark:w-[3.24vh] "
                      />
                      <img
                        alt="swap"
                        src="/icons/swap-dark.svg"
                        className="dark:invisible visible dark:w-0"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex-col justify-center items-center gap-[.96vh] flex flex-[0_0_auto]">
            <div className="mb-2 w-full">
              <SwapRouteSwitch
                routes={routes || []}
                selected={selectedRoute}
                onSelect={(item: TriaSwapRoute) => {
                  setSelectedRoute(item);
                }}
              />
            </div>
            <div className="self-stretch pb-[5.4vh] flex-col justify-center items-center gap-[1.92vh] flex">
              <div
                className={`${
                  isLite ? "px-3" : ""
                } self-stretch justify-center items-center gap-6 inline-flex`}
              >
                {isLite && (
                  <div
                    className={`${
                      isLite ? "w-full" : "w-[25.44vh]"
                    } h-[6.5vh] p-5 bg-gradient-to-r  rounded-[6.5vh] justify-center items-center flex  cursor-pointer ${
                      approveButtonDisable
                        ? "bg-white text-opacity-30 text-black disabled opacity-40 cursor-not-allowed"
                        : "bg-white text-black cursor-pointer"
                    }`}
                    onClick={() => {
                      if (!approveButtonDisable) {
                        handleSwap();
                      }
                    }}
                  >
                    <div className="justify-center items-center flex">
                      <div className="text-center font-montserrat text-black md:text-xl text-base font-semibold  leading-normal">
                        Review
                      </div>
                    </div>
                  </div>
                )}
                {!isLite && (
                  <div
                    className={`${
                      isLite ? "w-full" : "w-[25.44vh]"
                    } h-[6.5vh] p-5 bg-gradient-to-r  rounded-[6.5vh] justify-center items-center flex  cursor-pointer ${
                      approveButtonDisable
                        ? "bg-zinc-600  text-opacity-30 text-white disabled"
                        : "bg-gradient-to-r from-violet-400 to-indigo-500"
                    }`}
                    onClick={() => {
                      if (!approveButtonDisable) {
                        handleSwap();
                      }
                    }}
                  >
                    {loading ? (
                      <div className="dot-flashing" />
                    ) : (
                      <div className="justify-center items-center flex">
                        <div className="text-center font-montserrat text-white md:text-xl text-base font-semibold  leading-normal">
                          Review
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            {!isLite && (
              <>
                <div className="self-stretch dark:h-0 dark:w-0 h-[9.2vh] flex-col justify-center items-center dark:hidden gap-[1vh] flex rounded-b-[1vh] absolute w-full bottom-[0vh] nft-footer-bg-gradient left-0">
                  <Opentria />
                </div>
                <div className=" w-0 h-0 dark:h-[9.2vh] flex-col justify-center items-center gap-[1vh] hidden dark:flex  rounded-b-0 absolute dark:w-full bottom-[0vh] left-0">
                  <Opentria />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* {overlayVisible && (
        <>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "19.8vh",
              backdropFilter: "blur(.48vh)",
              WebkitBackdropFilter: "blur(.48vh)",
            }}
          />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              height: "20vh",
              backdropFilter: "blur(.48vh)",
              WebkitBackdropFilter: "blur(.48vh)",
            }}
          />
        </>
      )} */}
    </div>
  );
};
