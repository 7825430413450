import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFetchNfts, useUserPrefs } from "../../hooks";
import {
  CustomNftDataType,
  CustomTokenDataType,
  NftsItem,
  RootState,
  updateCurrentEditNft,
  updateCurrentEditToken,
} from "../../package/rx.core";
import { Nav, Opentria } from "../../package/ui.common";
import { BackButton } from "../../package/ui.common/src/components/Buttons/BackButton";
import SearchCustomToken from "../../package/ui.common/src/layouts/Search/SearchCustomToken";
import { CustomNftData } from "@tria-sdk/core";
import { useEffect, useState } from "react";
import { NftCard } from "../../package/ui.common/src/components/Cards";
import NftAssets from "../../package/ui.common/src/components/LoadersPages/NftAssets";

const CustomNft = () => {
  const navigate = useNavigate();
  const HandleClick = () => {
    navigate("/home");
  };
  const { data } = useUserPrefs();
  const { loading } = useFetchNfts();
  const nftItems = useSelector((store: RootState) => store.User?.userNfts);
  const [customNfts, setCustomNfts] = useState<NftsItem[]>([]);
  const dispatch = useDispatch();
  const handleTokenClick = (token: NftsItem) => {
    const val: CustomNftData | undefined = data?.customNfts?.find((nft) => {
      if (
        nft?.collectionAddress === token?.nftInfo?.contract_address &&
        nft?.tokenId === token?.nftInfo?.token_id &&
        nft?.type === token?.type
      ) {
        return nft;
      }
    });
    console.log("val found", val, token);
    if (!val) {
      return;
    }

    dispatch(updateCurrentEditNft({ ...val, chainId: val.chainId }));
    navigate("/editnft");
  };
  useEffect(() => {
    const newNftItems = nftItems?.filter((nft: NftsItem) => {
      if (nft?.isCustomToken) {
        return nft;
      }
    });

    setCustomNfts(newNftItems);
  }, [nftItems]);
  return (
    <div>
      <div className="flex w-[53vh] h-[100vh] px-[1.4vh] items-center justify-center relative  bg-primaryColor dark:bg-primaryDarkColor ">
        <div className="w-[100%] h-[98%] py-[2vh] flex-col justify-center items-center gap-[1.4vh] inline-flex  rounded-xl font-montserrat">
          <div className="w-full grow shrink flex-col justify-start items-center flex ">
            <Nav />
            {/* <Toast /> */}
            <div className="w-full h-[6.4vh] px-[2vh] py-[1.67vh] rounded-[2.4vh] flex-col justify-start items-center gap-[1.4vh] inline-flex ">
              <div className="self-stretch justify-start items-start gap-[1.6vh] flex flex-col ">
                <div className="h-[2.8vh] justify-start items-center gap-[1vh] flex">
                  <div className="w-[2vh] h-[2vh] justify-center items-center flex">
                    <div className="w-[1.67vh] h-[1.67vh] relative">
                      <BackButton onClick={HandleClick} />
                    </div>
                  </div>
                  <div className="text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[2.4vh] font-semibold leading-normal">
                    Custom Nfts
                  </div>
                </div>
                {/* <SearchCustomToken /> */}
                {/* <p className='text-center text-fontLightColor dark:text-fontLightColorDark text-opacity-60 text-[1.67vh]   leading-tight font-semibold tracking-tight ml-[1.5vh]'>
                  Add new network manually
                </p> */}
              </div>
            </div>
            <div className="w-full h-60%">
              {loading && <NftAssets />}
              {!loading && customNfts?.length > 0 && (
                <div className="h-[90%] overflow-y-scroll grid grid-cols-2 scrollbar-thin gap-[2.4vh] px-[2.4vh] scrollbar-thumb-gray-300 scrollbar-track-stone-100 pb-[9.5vh] ">
                  {customNfts?.map((nftItem, index) => (
                    <NftCard
                      key={index}
                      id={index}
                      loading={loading}
                      nftItem={nftItem}
                      onClick={() => handleTokenClick(nftItem)}
                    />
                  ))}
                </div>
              )}

              {!loading && !customNfts?.length && (
                <div className="flex flex-col gap-[1vh] items-center justify-center w-full h-[38vh]">
                  <img
                    src="./images/nonftimagedark.svg"
                    className="w-[17.8vh] h-[17.8vh]"
                    alt="nonft"
                  />
                  <p className="font-[600] text-[1.4vh] text-fontLightColor dark:text-fontLightColorDark">
                    You have no Custom NFTs right now
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 flex flex-col items-center gap-1 pb-2">
        <button
          className="bg-gray-200 dark:bg-white text-black py-[1.4vh] px-[2.4vh] w-[50vh] rounded-[3vh] text-[2vh] font-semibold leading-[120%]"
          onClick={() => navigate("/addcustomnft")}
        >
          ADD NEW NFT
        </button>
        <div className="dark:visible invisible w-full flex justify-center">
          <Opentria />
        </div>
      </div>
    </div>
  );
};

export default CustomNft;
