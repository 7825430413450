import { useQuery } from "@tanstack/react-query";
import { useTriaName } from "./useTriaName";

const fetchUserAvatar = async (
  triaName: string
): Promise<{ avatar?: string; background?: string }> => {
  if (!triaName) throw new Error("TriaName is required");

  const url = `${process.env.REACT_APP_SDK_BASE_URL}/api/v1/user/getAvatarByTriaName?triaNames=${triaName}`;
  const response = await fetch(url);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();

  if (data?.response?.[triaName]?.length > 0) {
    const userData = data.response[triaName][0];
    return {
      avatar: userData.avatar || null,
      background: userData.background || null,
    };
  }

  return { avatar: undefined, background: undefined };
};

export const useFetchUserAvatar = () => {
  const { triaName } = useTriaName();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["userAvatar", triaName],
    queryFn: () => fetchUserAvatar(triaName!),
    enabled: !!triaName, // Fetch only if triaName exists
    retry: 2, // Retry twice before failing
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
  });

  return {
    avatar: data?.avatar,
    background: data?.background,
    isLoading,
    isError,
    error,
  };
};
